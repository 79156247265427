<template>
  <div class="ml-5 mr-5 mt-10 secondary">
    <v-row
      class="ml-5 pt-3"
      v-if="showtop"
    >
      <v-tooltip
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="addDoc()"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >add_box</v-icon>
            <span class="accent--text text-h6 text-lowercase">Add new journal</span>
          </v-btn>
        </template>
        <span>Add new Journal</span>
      </v-tooltip>
    </v-row>
    <v-col
      v-if="editdoc || adddoc"
      xs12
      sm3
      md8
      class="mb-4"
    >
      <v-card class="primary">
        <v-card-title v-if="editdoc">
          <h2 class="accent--text">Edit Journal</h2>
        </v-card-title>
        <v-card-title v-else-if="adddoc">
          <h2 class="accent--text">Add New Journal</h2>
        </v-card-title>
        <v-card-text>
          <v-form
            class="px-3"
            ref="form"
            @submit.prevent="onSubmit"
          >
            <v-text-field
              type="text"
              class="formedit accent--text"
              v-model.lazy="curdoc.title"
            ></v-text-field>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  color="accent"
                  label="Date"
                  clearable
                  v-on="on"
                  v-model.lazy="curdoc.date"
                  readonly
                  class="note-title"
                ></v-text-field>
              </template>
              <v-date-picker
                scrollable
                width="400"
                v-model="curdoc.date"
                @change="menu = false"
              ></v-date-picker>
            </v-menu>
            <ckeditor
              :editor="editor"
              v-model.lazy="curdoc.essay"
              :config="editorConfig"
              class="ck-content"
            ></ckeditor>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              text
              @click="submit"
              class="black white--text mx-0 mt-3 mr-3"
            >Save Journal</v-btn>
            <v-btn
              text
              @click="
                editdoc = false;
                adddoc = false;
                showtop = true;
                dispdoc = false;
                curdoc = [];
              "
              class="black white--text mx-0 mt-3"
            >Cancel</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <div
      :key="refresh"
      class="tablediv"
    >
      <table
        class="mytable"
        v-if="!dispdoc && !(adddoc || editdoc)"
      >
        <tbody>
          <tr>
            <th
              @click.exact="sortBy('title')"
              @click.shift.exact="sortByD('title')"
              class="title accent--text font-weight-bold"
            >
              Title
            </th>
            <th
              @click.exact="sortBy('date')"
              @click.shift.exact="sortByD('date')"
              class="title accent--text font-weight-bold numbers dates"
            >
              Date
            </th>
            <th class="title accent--text font-weight-bold buttons">Actions</th>
          </tr>

          <tr
            v-for="project in projects"
            :key="project.id"
            row
            wrap
          >
            <td
              class="item-title item text--darken-3"
              @click="editTxt(project.id)"
            >
              {{ project.title }}
            </td>
            <td
              class="item-title numbers dates text--darken-3"
              @click="editTxt(project.id)"
            >
              {{ project.date }}
            </td>
            <td class="buttons">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    class="mr-4 material-icons md-48"
                    @click="editDoc(project.id)"
                    :class="{ filled: project.essay }"
                  >edit</v-icon>
                </template>
                <span>Edit Journal</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="my-2 material-icons md-48"
                    @click="delDoc(project.id)"
                  >delete</v-icon>
                </template>
                <span>Delete Journal</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-dialog
      v-model="dialog"
      width="70%"
    >
      <v-card
        row
        flat
        height="auto"
        class="primary"
      >
        <v-card-text class="mt-0 pt-0">
          <v-card-actions
            row
            class="primary mt-5"
          >
            <v-btn
              large
              text
              color="accent"
              @click="dialog = false"
            >
              <v-icon
                color="success"
                large
                left
              >close</v-icon>
              <span class="success--text body-1 text-lowercase">Close</span>
            </v-btn>
          </v-card-actions>
          <v-row class="primary">
            <v-col
              sm="12"
              md="12"
              lg="10"
              xl="8"
            >
              <h1 class="success--text">{{ curdoc.title }}</h1>
            </v-col>
          </v-row>
          <v-row class="primary">
            <v-col
              class="ck-content"
              sm="12"
              md="12"
              lg="10"
              xl="8"
            >
              <hr class="secondary thick" />
              <div
                class="primary mt-5"
                v-html="curdoc.essay"
              ></div>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { shared } from "../mixins/shared";
import store from "../store/store.js";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import LineHeight from "ckeditor5-line-height-plugin/src/lineheight";
import List from "@ckeditor/ckeditor5-list/src/list";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Font from "@ckeditor/ckeditor5-font/src/font";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";

export default {
  mixins: [shared],
  props: {
    tid: {
      type: String,
      required: true,
      default: "journal",
    },
  },
  data() {
    return {
      dialog: false,
      items: ["save", "publish"],
      values: ["save", "publish"],
      projects: [],
      refresh: 0,
      editdoc: false,
      adddoc: false,
      dispdoc: false,
      showtop: true,
      upimg: false,
      curdoc: [],
      curid: 0,
      menu: false,
      picurl: "https://vsgpartners.com/images/",
      url: "https://vsgpartners.com/data.php",
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          Underline,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          Image,
          ImageToolbar,
          ImageStyle,
          ImageResize,
          ImageUpload,
          List,
          // EasyImage,
          LineHeight,
          SimpleUploadAdapter,
          Heading,
          Alignment,
          Font,
          Strikethrough,
          Subscript,
          Superscript,
          BlockQuote,
          Indent,
          IndentBlock,
        ],
        indentBlock: {
          classes: [
            "custom-block-indent-a", // First step - smallest indentation.
            "custom-block-indent-b",
            "custom-block-indent-c", // Last step - biggest indentation.
          ],
        },
        lineHeight: {
          // specify your otions in the lineHeight config object. Default values are [ 0, 0.5, 1, 1.5, 2 ]
          options: [0.5, 1, 1.5, 1.75, 2, 2.5],
        },
        image: {
          resizeUnit: "px",
          // You need to configure the image toolbar, too, so it uses the new style buttons.
          toolbar: [
            "imageTextAlternative",
            "|",
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
          ],

          styles: [
            "alignCenter",

            // This represents an image aligned to the left.
            "alignLeft",

            // This represents an image aligned to the right.
            "alignRight",
          ],
        },
        alignment: {
          options: ["left", "right"],
        },
        fontSize: {
          options: ["tiny", "default", "big"],
        },
        toolbar: {
          items: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "subscript",
            "superscript",
            "|",
            "numberedList",
            "bulletedList",
            "alignment",
            "outdent",
            "indent",
            "|",
            "blockQuote",
            "|",
            "link",
            "|",
            "imageUpload",
            "heading",
            "|",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "lineHeight",
          ],
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,

          // Let the users control the "download" attribute of each link.
          decorators: [
            {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "download",
              },
            },
          ],
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: "https://vsgpartners.com/upload.php?CKEditorFuncNum=1",

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            "X-CSRF-TOKEN": "CSFR-Token",
            Authorization: "Bearer <JSON Web Token>",
          },
        },
      },
    };
  },
  methods: {
    submit() {
      var url = this.url;
      var db = store.durl;
      var action;
      if (this.editdoc) {
        action = "submit-j";
      } else {
        action = "add-j";
      }
      var formdata = {
        action: action,
        id: this.curdoc.id,
        db: db,
        tid: this.tid,
        title: this.curdoc.title,
        date: this.curdoc.date,
        essay: this.curdoc.essay,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {
          this.getDocs("displayj");
        })
        .catch((error) => alert(error));
      this.editdoc = false;
      this.adddoc = false;
      this.curdoc = [];
      this.dispdoc = false;
      this.showtop = true;
    },
    editTxt(id) {
      for (var i = 0; i < this.projects.length; i++) {
        if (this.projects[i].id === id) {
          this.curdoc.essay = this.projects[i].essay;
          this.curdoc.title = this.projects[i].title;
          if (this.curdoc.essay) {
            this.dialog = true;
          }
        }
      }
    },
    editDoc(id) {
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "edit-j",
        id: id,
        tid: this.tid,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.curdoc = data[0];
          this.editdoc = true;
          this.showtop = false;
          this.dispdoc = false;
        })
        .catch((error) => alert(error));
    },
    addDoc() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      this.curdoc.date = yyyy + "-" + mm + "-" + dd;
      this.adddoc = true;
      this.showtop = false;
      this.dispdoc = false;
    },
    delDoc(id) {
      if (confirm("Do you really want to delete?")) {
        var url = this.url;
        var db = store.durl;
        var formdata = {
          action: "del-j",
          id: id,
          tid: this.tid,
          db: db,
        };
        this.axios
          .post(url, formdata, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then(() => {
            this.getDocs("displayj");
          })
          .catch((error) => alert(error));
      }
    },
    getDocs() {
      this.projects = [];
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "displayj",
        tid: this.tid,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          this.projects = res.data;
        })
        .catch((error) => alert(error));
    },
  },
  created() {
    var url = this.url;
    var db = store.durl;
    var formdata = {
      action: "displayj",
      tid: this.tid,
      db: db,
    };
    this.axios
      .post(url, formdata, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        const data = res.data;
        this.projects = data;
      })
      .catch((error) => alert(error));
  },
};
</script>

<style scoped>
.img-one {
  border-radius: 10%;
}

.filled {
  color: red;
}

.item {
  width: 50%;
}

.dates {
  text-align: left;
}
</style>
